import React, { useState } from 'react';
import { Menu } from 'antd';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './header.css';
import Cookies from 'js-cookie';
import { googleLogout } from '@react-oauth/google';

const { SubMenu, Item } = Menu;

const Header = ({ webCategories, subs }) => {
  const [current, setCurrent] = useState('home');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const navigation = useNavigate();

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const logout = () => {
    Cookies.remove('user');
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigation('/login');
  };

  const logoutGoogle = () => {
    googleLogout();
    Cookies.remove('user');
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
    navigation('/login');
  };

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      className="home_nav_main"
    >
      <Item key="home" className="home_nav">
        <Link to="/" as={NavLink}>
          <img
            src="https://res.cloudinary.com/dybybovyi/image/upload/v1713290973/logo_o4fpmi.png"
            alt="Logo"
            style={{ width: '130px', marginTop: '13px' }}
          />
        </Link>
      </Item>

      {user && (
        <SubMenu
          icon={<SettingOutlined />}
          title={user.firstName && user.firstName}
          className="float-right home_nav_icon"
        >
          {user && user.role === 'author' && (
            <Item>
              <Link to="/user/dashboard" as={NavLink}>
                Dashboard
              </Link>
            </Item>
          )}

          {user && user.role === 'admin' && (
            <Item>
              <Link to="/admin/dashboard" as={NavLink}>
                Dashboard
              </Link>
            </Item>
          )}

          <Item
            icon={<LogoutOutlined />}
            onClick={() => (user?.google ? logoutGoogle() : logout())}
            className="home_nav_icon"
          >
            Logout
          </Item>
        </SubMenu>
      )}

      {!user && (
        <Item
          key="signup"
          // icon={<UserAddOutlined />}
          className="float-right"
        >
          <Link to="/signup" as={NavLink}>
            <span className="text-white px-5 py-1 bg-cyan-500 hover:bg-cyan-800 rounded">
              Signup
            </span>
          </Link>
        </Item>
      )}

      {!user && (
        <Item
          key="login"
          // icon={<UserOutlined />}
          className="float-right home_nav_icon"
        >
          <Link to="/login" as={NavLink}>
            <span className="text-cyan-600 px-5 py-1 border-cyan-500 border-1 hover:bg-cyan-800 rounded">
              Login
            </span>
          </Link>
        </Item>
      )}

      {webCategories &&
        webCategories
          ?.slice(0, 7)
          ?.sort((a, b) => (a < b ? 1 : -1))
          ?.map((c) => {
            return (
              <SubMenu
                className="float-right home_nav_icon"
                key={c?._id}
                title={c?.name}
                onTitleClick={() => navigation(`/category/${c?.slug}`)}
              >
                {subs
                  ?.filter((newC) => newC.parent === c?._id)
                  ?.map((sub) => (
                    <Item key={sub?._id} className="home_nav_icon">
                      <Link to={`/sub-category/${sub?.slug}`} as={NavLink}>
                        {sub?.name}
                      </Link>
                    </Item>
                  ))}
              </SubMenu>
            );
          })}
    </Menu>
  );
};

export default Header;
