import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';
import LoginFormInput from './LoginFormInput';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';

const codeInputs = Array(6).fill('');
let newInputCodeIndex = 0;

const Login = () => {
  const inputCodeRef = useRef();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' });
  const [nextInputCodeIndex, setNextInputCodeIndex] = useState(0);

  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const inputLogin = [
    {
      id: 1,
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email address',
      errorMessage: 'It should be a valid email address!',
      label: 'Email address',
      required: true,
    },
    {
      id: 2,
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      errorMessage: 'Password is required',
      label: 'Password',
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const roleRedirect = () => {};

  useEffect(() => {
    inputCodeRef?.current?.focus();
  }, [nextInputCodeIndex]);

  const isObjValid = (obj) => {
    return Object.values(obj).every((val) => val.trim());
  };

  const handleChangeCodeText = (e, index) => {
    const { value } = e.target;

    const cdvalue = value.replace(/\D/g, '');
    const newOtp = { ...otp };
    newOtp[index] = cdvalue;
    setOtp(newOtp);

    const lastInputCodeIndex = codeInputs.length - 1;

    if (!cdvalue) newInputCodeIndex = index === 0 ? 0 : index - 1;
    else
      newInputCodeIndex =
        index === codeInputs.length - 1 ? lastInputCodeIndex : index + 1;

    setNextInputCodeIndex(newInputCodeIndex);
  };

  const handleVerification = async () => {
    let val = '';
    Object.values(otp).forEach((v) => {
      val += v;
    });
    setLoading(true);
    if (val.length < 6) {
      toast.error('Please fill all code fields');
      setLoading(false);
    } else {
      if (isObjValid(otp)) {
        let val = '';
        Object.values(otp).forEach((v) => {
          val += v;
        });

        if (!val) {
          alert('Please enter the code');
          setLoading(false);
          return;
        }

        let vData = {
          email: values.email,
          vcode: val,
        };
        try {
          setLoading(true);
          const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}completeregiter`,
            vData
          );
          const { message, ...rest } = data;
          setError('');
          setSuccess('Account successfully verified.');
          setTimeout(() => {
            dispatch({ type: 'LOGIN', payload: rest });
            Cookies.set('user', JSON.stringify(rest), {
              expires: 30,
            });
            navigate('/');
            setSuccess('');
            setStep(1);
          }, 2000);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setSuccess('');
          setError(error.response.data.message);
        }
        setLoading(false);
      }
    }
  };

  const handleSteps = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <form onSubmit={onSubmit}>
              {inputLogin.map((input) => (
                <LoginFormInput
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                />
              ))}
              <Link
                to="/forgot-password"
                className="text-xs text-cyan-500 hover:underline"
              >
                Forget Password?
              </Link>
              <div className="mt-6">
                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cyan-500 rounded-md hover:bg-cyan-500 focus:outline-none focus:bg-cyan-600">
                  Login
                </button>
              </div>
            </form>

            <p className="w-full my-6 text-black text-center">OR</p>

            <div className="flex justify-center items-center ">
              <GoogleOAuthProvider clientId="3736493883-7hmb6gk2sm6klsdv5vm1ehk922kfdvnm.apps.googleusercontent.com">
                <GoogleLogin
                  useOneTap
                  onSuccess={async (credentialResponse) => {
                    setLoading(true);
                    const response = await axios.post(
                      'https://backend.coinaudit.co.uk/api/google-login',
                      {
                        tokenGoogle: credentialResponse.credential,
                      }
                    );
                    setLoading(false);
                    await handleGoogleLogin(response.data);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        );
      case 2:
        return (
          <>
            <h4 className="text-center auth_verify_text text-muted my-4">
              Please enter verfication code for email verification
            </h4>
            <div className="flex-container">
              {codeInputs.map((inp, index) => {
                return (
                  <input
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-cyan-700 bg-white border rounded-md focus:border-cyan-700 focus:ring-cyan-700 focus:outline-none focus:ring focus:ring-opacity-40"
                    maxLength={1}
                    value={otp[index]}
                    placeholder="0"
                    name="vcode"
                    onChange={(e) => handleChangeCodeText(e, index)}
                    ref={nextInputCodeIndex === index ? inputCodeRef : null}
                    key={index}
                  />
                );
              })}
            </div>
            <br />
            <div className="d-grid gap-2">
              <button
                onClick={handleVerification}
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cyan-500 rounded-md hover:bg-cyan-500 focus:outline-none focus:bg-cyan-600"
              >
                Verify Code
              </button>
            </div>
            <br />
          </>
        );
      default:
        return null;
    }
  };

  const handleGoogleLogin = async (gData) => {
    try {
      setTimeout(() => {
        dispatch({ type: 'LOGIN', payload: gData });

        Cookies.set('user', JSON.stringify(gData), {
          expires: 30,
        });
        navigate('/');
        setSuccess('');
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setError('');
    setSuccess('');
    const { email, password } = values;

    if (email.length > 0 && password.length > 0) {
      let logData = {
        email,
        password,
      };

      try {
        setLoading(true);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}login`,
          logData
        );
        const { message, ...rest } = data;
        setError('');
        setSuccess('Login success! Redirecting...');
        setTimeout(() => {
          dispatch({ type: 'LOGIN', payload: rest });
          // var date = new Date()
          // var minutes = 1
          // date.setTime(date.getTime() + minutes * 60 * 1000)
          Cookies.set('user', JSON.stringify(rest), {
            expires: 30,
          });
          navigate('/');
          setSuccess('');
        }, 1500);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setSuccess('');
        setError(error.response.data.message);
      }
    } else {
      setLoading(false);
    }
  };

  const newOtpGenerate = async () => {
    setError(null);
    setSuccess(null);
    let vData = {
      email: values.email,
    };
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}resendverify`,
        vData
      );

      setError('');
      setSuccess(data.message);
      setStep(2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSuccess('');
      setError(error.response.data.message);
    }
  };

  return (
    <div className="relative flex flex-col justify-center min-h-590 overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow sm:max-w-xl lg:max-w-xl my-6">
        <section className="hero container max-w-screen-lg mx-auto pb-2">
          <img
            src="https://res.cloudinary.com/dwn02nfdv/image/upload/v1668858388/user-soccerstatz_ewupwx_xkmqab.png"
            alt="Profile"
            className="auth_member_img mx-auto"
          />
        </section>
        <h1 className="text-2xl font-semibold text-center text-cyan-500 mb-4">
          Member login
        </h1>

        {loading && (
          <div className="flex justify-center items-center">
            <FadeLoader color="#06b6d4" loading={loading} size={20} />
          </div>
        )}

        {success && (
          <h5 className="text-center auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
            <CheckCircleFilled
              style={{
                fontSize: '22px',
                color: 'green',
                marginRight: '5px',
                position: 'relative',
                top: '-3px',
              }}
            />
            {success}
          </h5>
        )}
        {error && (
          <h5 className="text-center auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
            {' '}
            <ExclamationCircleOutlined
              style={{
                fontSize: '20px',
                color: 'red',
                marginRight: '5px',
                position: 'relative',
                top: '-3px',
              }}
            />{' '}
            {error}
          </h5>
        )}

        {error === 'Please verify your email address' && (
          <h5 className="text-center auth_error_success my-3 text-danger">
            Get new{' '}
            <Button onClick={newOtpGenerate} className="otp_gen_btn">
              OTP Here
            </Button>
          </h5>
        )}

        {handleSteps()}
        <h6 className="text-center text-muted mt-6 mb-4">
          Not a member?{' '}
          <Link to="/signup" as={NavLink} className="auth_link">
            Signup
          </Link>{' '}
        </h6>
      </div>
    </div>
  );
};

export default Login;
